// const getHardcodedData = {
// 	kind: 'youtube#searchListResponse',
// 	etag: 'x6RavMwDwj6hHkzotkzSLheJ8EI',
// 	nextPageToken: 'CAoQAA',
// 	regionCode: 'BG',
// 	pageInfo: {
// 		totalResults: 1000000,
// 		resultsPerPage: 10,
// 	},
// 	items: [
// 		{
// 			kind: 'youtube#searchResult',
// 			etag: 'iFP7AyXEON2V5vPYhfhzQjQmxLM',
// 			id: {
// 				kind: 'youtube#video',
// 				videoId: 'DXUAyRRkI6k',
// 			},
// 			snippet: {
// 				publishedAt: '2013-11-09T22:11:37Z',
// 				channelId: 'UCVUdHi-tdW5AKdzMiTPG97Q',
// 				title: 'Funny Cats and Kittens Meowing Compilation',
// 				description:
// 					'Here is a video of cats and kittens meowing to confuse your pets Puppies & Babies & Kitties OH MY! New videos all the time! Subscribe: ...',
// 				thumbnails: {
// 					default: {
// 						url: 'https://i.ytimg.com/vi/DXUAyRRkI6k/default.jpg',
// 						width: 120,
// 						height: 90,
// 					},
// 					medium: {
// 						url: 'https://i.ytimg.com/vi/DXUAyRRkI6k/mqdefault.jpg',
// 						width: 320,
// 						height: 180,
// 					},
// 					high: {
// 						url: 'https://i.ytimg.com/vi/DXUAyRRkI6k/hqdefault.jpg',
// 						width: 480,
// 						height: 360,
// 					},
// 				},
// 				channelTitle: 'funnyplox',
// 				liveBroadcastContent: 'none',
// 				publishTime: '2013-11-09T22:11:37Z',
// 			},
// 		},
// 		{
// 			kind: 'youtube#searchResult',
// 			etag: 'L9wNsTq73x0ccQcnafDmfkvfnTw',
// 			id: {
// 				kind: 'youtube#video',
// 				videoId: 'hY7m5jjJ9mM',
// 			},
// 			snippet: {
// 				publishedAt: '2017-05-31T09:30:02Z',
// 				channelId: 'UC9obdDRxQkmn_4YpcBMTYLw',
// 				title: 'CATS will make you LAUGH YOUR HEAD OFF - Funny CAT compilation',
// 				description:
// 					'Cats are amazing creatures because they make us laugh all the time! Watching funny cats is the hardest try not to laugh challenge! Just look how all these cats ...',
// 				thumbnails: {
// 					default: {
// 						url: 'https://i.ytimg.com/vi/hY7m5jjJ9mM/default.jpg',
// 						width: 120,
// 						height: 90,
// 					},
// 					medium: {
// 						url: 'https://i.ytimg.com/vi/hY7m5jjJ9mM/mqdefault.jpg',
// 						width: 320,
// 						height: 180,
// 					},
// 					high: {
// 						url: 'https://i.ytimg.com/vi/hY7m5jjJ9mM/hqdefault.jpg',
// 						width: 480,
// 						height: 360,
// 					},
// 				},
// 				channelTitle: 'Tiger FunnyWorks',
// 				liveBroadcastContent: 'none',
// 				publishTime: '2017-05-31T09:30:02Z',
// 			},
// 		},
// 		{
// 			kind: 'youtube#searchResult',
// 			etag: 'D-WTfKQELP7cgXb4X4uDS8WErXU',
// 			id: {
// 				kind: 'youtube#video',
// 				videoId: 'ByH9LuSILxU',
// 			},
// 			snippet: {
// 				publishedAt: '2020-06-19T02:18:53Z',
// 				channelId: 'UC8hC-augAnujJeprhjI0YkA',
// 				title:
// 					'Baby Cats - Cute and Funny Cat Videos Compilation #34 | Aww Animals',
// 				description:
// 					'Baby cats are amazing creature because they are the cutest and most funny. Watching funny baby cats is the hardest try not to laugh challenge. This is the cutest ...',
// 				thumbnails: {
// 					default: {
// 						url: 'https://i.ytimg.com/vi/ByH9LuSILxU/default.jpg',
// 						width: 120,
// 						height: 90,
// 					},
// 					medium: {
// 						url: 'https://i.ytimg.com/vi/ByH9LuSILxU/mqdefault.jpg',
// 						width: 320,
// 						height: 180,
// 					},
// 					high: {
// 						url: 'https://i.ytimg.com/vi/ByH9LuSILxU/hqdefault.jpg',
// 						width: 480,
// 						height: 360,
// 					},
// 				},
// 				channelTitle: 'Aww Animals',
// 				liveBroadcastContent: 'none',
// 				publishTime: '2020-06-19T02:18:53Z',
// 			},
// 		},
// 		{
// 			kind: 'youtube#searchResult',
// 			etag: 'x3l18CqXgphSOPIDMJqdAcdzD3U',
// 			id: {
// 				kind: 'youtube#video',
// 				videoId: 'bq0NF7FnSws',
// 			},
// 			snippet: {
// 				publishedAt: '2020-09-24T10:32:54Z',
// 				channelId: 'UCcChic4IF6tk-lmp9z-56sw',
// 				title:
// 					'Play Little Kitten My Favorite Cat Pet Care Game - Fun Baby Kitten Animation Mini Games For Children',
// 				description:
// 					"How exciting! Little Kitten gets invited to a Dress Up Party! ...but the way there is full of little incidents waiting to happen. Pack Little Kitten's backpack to make it to ...",
// 				thumbnails: {
// 					default: {
// 						url: 'https://i.ytimg.com/vi/bq0NF7FnSws/default_live.jpg',
// 						width: 120,
// 						height: 90,
// 					},
// 					medium: {
// 						url: 'https://i.ytimg.com/vi/bq0NF7FnSws/mqdefault_live.jpg',
// 						width: 320,
// 						height: 180,
// 					},
// 					high: {
// 						url: 'https://i.ytimg.com/vi/bq0NF7FnSws/hqdefault_live.jpg',
// 						width: 480,
// 						height: 360,
// 					},
// 				},
// 				channelTitle: 'iMilkin',
// 				liveBroadcastContent: 'live',
// 				publishTime: '2020-09-24T10:32:54Z',
// 			},
// 		},
// 		{
// 			kind: 'youtube#searchResult',
// 			etag: 'XDX1WC3Gelp7o6C06ziuq9dA-mk',
// 			id: {
// 				kind: 'youtube#video',
// 				videoId: '_f3v4gQYn3w',
// 			},
// 			snippet: {
// 				publishedAt: '2020-09-23T16:40:18Z',
// 				channelId: 'UCcChic4IF6tk-lmp9z-56sw',
// 				title:
// 					'Little Kitten &amp; Friends - Learn with the Cutest Cat Best App for Kids - learning for children',
// 				description:
// 					'Yay, Little Kitten is back and so excited about going to primary school! Our app „Little Kitten Preschool“ helps preparing children aged between 4 and 7 for their ...',
// 				thumbnails: {
// 					default: {
// 						url: 'https://i.ytimg.com/vi/_f3v4gQYn3w/default_live.jpg',
// 						width: 120,
// 						height: 90,
// 					},
// 					medium: {
// 						url: 'https://i.ytimg.com/vi/_f3v4gQYn3w/mqdefault_live.jpg',
// 						width: 320,
// 						height: 180,
// 					},
// 					high: {
// 						url: 'https://i.ytimg.com/vi/_f3v4gQYn3w/hqdefault_live.jpg',
// 						width: 480,
// 						height: 360,
// 					},
// 				},
// 				channelTitle: 'iMilkin',
// 				liveBroadcastContent: 'live',
// 				publishTime: '2020-09-23T16:40:18Z',
// 			},
// 		},
// 		{
// 			kind: 'youtube#searchResult',
// 			etag: 'pE38tIfZmN0i-9bfSy5-93F2RKo',
// 			id: {
// 				kind: 'youtube#video',
// 				videoId: 'BwSQyuUxYfI',
// 			},
// 			snippet: {
// 				publishedAt: '2020-07-10T15:00:27Z',
// 				channelId: 'UC8P4HIRzjjnxU-MQX0QHLUw',
// 				title: 'Two Cats and 500 Balls in a Ball Pit !!',
// 				description:
// 					'I surprised my two cats with a huge ball pit filled with 500 multi-colored balls! My cats went wild and played like crazy in that pit! My email address: ...',
// 				thumbnails: {
// 					default: {
// 						url: 'https://i.ytimg.com/vi/BwSQyuUxYfI/default.jpg',
// 						width: 120,
// 						height: 90,
// 					},
// 					medium: {
// 						url: 'https://i.ytimg.com/vi/BwSQyuUxYfI/mqdefault.jpg',
// 						width: 320,
// 						height: 180,
// 					},
// 					high: {
// 						url: 'https://i.ytimg.com/vi/BwSQyuUxYfI/hqdefault.jpg',
// 						width: 480,
// 						height: 360,
// 					},
// 				},
// 				channelTitle: 'walter santi',
// 				liveBroadcastContent: 'none',
// 				publishTime: '2020-07-10T15:00:27Z',
// 			},
// 		},
// 		{
// 			kind: 'youtube#searchResult',
// 			etag: 'fozsiR9LcfVUIq760jvU3Jj5oik',
// 			id: {
// 				kind: 'youtube#video',
// 				videoId: 'jpYDw7AJDtM',
// 			},
// 			snippet: {
// 				publishedAt: '2016-03-08T03:37:51Z',
// 				channelId: 'UChbV7GOWui74FJoHLCLwVSw',
// 				title: 'Cat Dealers - Your Body (Remix)',
// 				description:
// 					'Vote Cat Dealers DJ Mag 2020: https://bit.ly/vote_catdealers SUBSCRIBE TO OUR CHANNEL: https://bit.ly/CatDealersYT Follow Cat Dealers: Instagram: ...',
// 				thumbnails: {
// 					default: {
// 						url: 'https://i.ytimg.com/vi/jpYDw7AJDtM/default.jpg',
// 						width: 120,
// 						height: 90,
// 					},
// 					medium: {
// 						url: 'https://i.ytimg.com/vi/jpYDw7AJDtM/mqdefault.jpg',
// 						width: 320,
// 						height: 180,
// 					},
// 					high: {
// 						url: 'https://i.ytimg.com/vi/jpYDw7AJDtM/hqdefault.jpg',
// 						width: 480,
// 						height: 360,
// 					},
// 				},
// 				channelTitle: 'Cat Dealers',
// 				liveBroadcastContent: 'none',
// 				publishTime: '2016-03-08T03:37:51Z',
// 			},
// 		},
// 		{
// 			kind: 'youtube#searchResult',
// 			etag: '3I7s4d6rnpm2u5tgRvlyHN_p-3o',
// 			id: {
// 				kind: 'youtube#video',
// 				videoId: 'zQIawgxoox4',
// 			},
// 			snippet: {
// 				publishedAt: '2020-06-24T13:25:19Z',
// 				channelId: 'UC8hC-augAnujJeprhjI0YkA',
// 				title:
// 					'Baby Cats - Cute and Funny Cat Videos Compilation #35 | Aww Animals',
// 				description:
// 					'Baby cats are amazing creature because they are the cutest and most funny. Watching funny baby cats is the hardest try not to laugh challenge. This is the cutest ...',
// 				thumbnails: {
// 					default: {
// 						url: 'https://i.ytimg.com/vi/zQIawgxoox4/default.jpg',
// 						width: 120,
// 						height: 90,
// 					},
// 					medium: {
// 						url: 'https://i.ytimg.com/vi/zQIawgxoox4/mqdefault.jpg',
// 						width: 320,
// 						height: 180,
// 					},
// 					high: {
// 						url: 'https://i.ytimg.com/vi/zQIawgxoox4/hqdefault.jpg',
// 						width: 480,
// 						height: 360,
// 					},
// 				},
// 				channelTitle: 'Aww Animals',
// 				liveBroadcastContent: 'none',
// 				publishTime: '2020-06-24T13:25:19Z',
// 			},
// 		},
// 		{
// 			kind: 'youtube#searchResult',
// 			etag: '6lPgDUkCigvEiOM1qckRAgru-BY',
// 			id: {
// 				kind: 'youtube#video',
// 				videoId: 'iwMqsKDTdQ0',
// 			},
// 			snippet: {
// 				publishedAt: '2020-09-20T13:00:18Z',
// 				channelId: 'UCNo5PGwGmfnEprEUUb9AGgw',
// 				title: 'The Cat Cooking Show 2',
// 				description:
// 					'Prince Michael tries his best on a new cooking game show. Go to http://bit.ly/ARAaronsAnimalsYTB to download Animal Restaurant #AnimalRestaurant Check ...',
// 				thumbnails: {
// 					default: {
// 						url: 'https://i.ytimg.com/vi/iwMqsKDTdQ0/default.jpg',
// 						width: 120,
// 						height: 90,
// 					},
// 					medium: {
// 						url: 'https://i.ytimg.com/vi/iwMqsKDTdQ0/mqdefault.jpg',
// 						width: 320,
// 						height: 180,
// 					},
// 					high: {
// 						url: 'https://i.ytimg.com/vi/iwMqsKDTdQ0/hqdefault.jpg',
// 						width: 480,
// 						height: 360,
// 					},
// 				},
// 				channelTitle: 'AaronsAnimals',
// 				liveBroadcastContent: 'none',
// 				publishTime: '2020-09-20T13:00:18Z',
// 			},
// 		},
// 		{
// 			kind: 'youtube#searchResult',
// 			etag: 'QZrxjouL7y7cvfW_0D8M0oVhd0U',
// 			id: {
// 				kind: 'youtube#video',
// 				videoId: '7na_-LfGfpE',
// 			},
// 			snippet: {
// 				publishedAt: '2018-09-27T08:08:38Z',
// 				channelId: 'UCPMwKz6-urUB2AZ2N1F4ywg',
// 				title: 'Baby and Cat Fun and Fails - Funny Baby Video',
// 				description:
// 					'Baby and Cat Fun and Fails - Funny Baby Video 1:55 Not in time 3:26 oh snack 5:09 when you talk to your crush 8:00 Cat: "Please return me to ...',
// 				thumbnails: {
// 					default: {
// 						url: 'https://i.ytimg.com/vi/7na_-LfGfpE/default.jpg',
// 						width: 120,
// 						height: 90,
// 					},
// 					medium: {
// 						url: 'https://i.ytimg.com/vi/7na_-LfGfpE/mqdefault.jpg',
// 						width: 320,
// 						height: 180,
// 					},
// 					high: {
// 						url: 'https://i.ytimg.com/vi/7na_-LfGfpE/hqdefault.jpg',
// 						width: 480,
// 						height: 360,
// 					},
// 				},
// 				channelTitle: 'Fun and Fails',
// 				liveBroadcastContent: 'none',
// 				publishTime: '2018-09-27T08:08:38Z',
// 			},
// 		},
// 	],
// };

const hardcodedFavorites = [
	{
		videoId: '7Nn7NZI_LN4',
		title: 'Cats and Domino',
		iframeUrl: 'https://www.youtube.com/embed/7Nn7NZI_LN4',
		thumbnail: 'https://i.ytimg.com/vi/7Nn7NZI_LN4/mqdefault.jpg',
		source: 'youtube',
		isBookmarked: true,
	},
	{
		videoId: 'q_7Y24A6oFY',
		title: 'Funny Cats ✪ Cute and Baby Cats Videos Compilation #85',
		iframeUrl: 'https://www.youtube.com/embed/q_7Y24A6oFY',
		thumbnail: 'https://i.ytimg.com/vi/q_7Y24A6oFY/mqdefault.jpg',
		source: 'youtube',
		isBookmarked: true,
	},
	{
		videoId: 'x7y8uuv',
		title: 'Standing Cat Gets Clean in Shower',
		iframeUrl: 'https://www.dailymotion.com/embed/video/x7y8uuv',
		thumbnail: 'https://s1.dmcdn.net/v/Sg2mt1VuYZHDNBcEE/x180',
		source: 'dailymotion',
		isBookmarked: true,
		page: 1,
	},
	{
		videoId: 'x7y8u8p',
		title:
			'Cô gái ngành CÔNG AN làm Quyền Linh LẦN ĐẦU ĐỎ MẶT - gặp chàng trai có phong cách GIỐNG Y ông mai',
		iframeUrl: 'https://www.dailymotion.com/embed/video/x7y8u8p',
		thumbnail: 'https://s1.dmcdn.net/v/Sg2aP1VuYIEH17vsR/x180',
		source: 'dailymotion',
		isBookmarked: true,
		page: 1,
	},
	{
		videoId: '/videos/288513548',
		title: 'House',
		iframeUrl: 'https://player.vimeo.com/video/288513548',
		thumbnail: 'https://i.vimeocdn.com/video/723948748_295x166.jpg?r=pad',
		source: 'vimeo',
		isBookmarked: true,
	},
	{
		videoId: '/videos/69154467',
		title: 'Idea #Project: House The House',
		iframeUrl: 'https://player.vimeo.com/video/69154467',
		thumbnail: 'https://i.vimeocdn.com/video/441797921_295x166.jpg?r=pad',
		source: 'vimeo',
		isBookmarked: true,
	},
	{
		videoId: '/videos/96780174',
		title: 'Sunset House • Stone House',
		iframeUrl: 'https://player.vimeo.com/video/96780174',
		thumbnail: 'https://i.vimeocdn.com/video/477053786_295x166.jpg?r=pad',
		source: 'vimeo',
		isBookmarked: true,
	},
	{
		videoId: '/videos/27041093',
		title: 'House',
		iframeUrl: 'https://player.vimeo.com/video/27041093',
		thumbnail: 'https://i.vimeocdn.com/video/179175767_295x166.jpg?r=pad',
		source: 'vimeo',
		isBookmarked: true,
	},
	{
		videoId: '/videos/7039690',
		title: 'House Fire',
		iframeUrl: 'https://player.vimeo.com/video/7039690',
		thumbnail: 'https://i.vimeocdn.com/video/28962472_295x166.jpg?r=pad',
		source: 'vimeo',
		isBookmarked: true,
	},
	{
		videoId: '/videos/1365059',
		title: 'House Rules',
		iframeUrl: 'https://player.vimeo.com/video/1365059',
		thumbnail: 'https://i.vimeocdn.com/video/58431461_295x166.jpg?r=pad',
		source: 'vimeo',
		isBookmarked: true,
	},
];

export { hardcodedFavorites };
